<template>
  <div class="form-controller col-12" :class="border">
    <div class="col-12">
      <div class="row justify-content-start">
        <div class="rtl">
          <label :for="id" class="form-label">{{ label }} : </label>
        </div>
        <div class="col-9">
          <textarea :rows="rows" :id="id" class="form-input-text"
          :value="value"
          v-bind="$attrs"
          v-on="listeners"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'TextAreaInput',
  props: {
    id:String,
    border:String,
    label:String,
    rows:String,
    value: {
        type: [String, Number],
        description: "Input value"
      },
  },
  computed: {
      hasIcon() {
        const { addonRight, addonLeft } = this.$slots;
        return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
      },
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput,
          blur: this.onBlur,
          focus: this.onFocus
        }
      }
    },
    methods: {
      onInput(evt) {
        this.$emit('input', evt.target.value)
      },
      onFocus() {
        this.focused = true;
      },
      onBlur() {
        this.focused = false;
      }
    }
}
</script>

<style lang="css" scoped>
.form-controller{
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-top: none;
  padding-top: 7px;
  padding-bottom: 7px;
}
.form-label{
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}
.form-input-text{
  width: 100%;
  background: linear-gradient(90deg,rgba(255,255,255,0) 0% ,rgba(255,255,255,0.2)100%);
  border: none;
  color: #fff;
}
.rtl{
  direction: rtl;
  text-align: right;
}
</style>
